<template>
	<div>
		<el-card class="box-card" ref="content">
			<div slot="header" class="clearfix">
				<span class="font-weight-bolder">基本信息</span>
			</div>
			<el-form :inline="true" :model="serviceData" :rules="serviceRules" ref="serviceRuleForm">
				<el-form-item label="客户姓名" prop="name" :label-width="formLabelWidth">
					<el-input v-model="serviceData.name" autocomplete="off" size="small">
					</el-input>
				</el-form-item>
				<el-form-item label="联系方式" prop="mobile" :label-width="formLabelWidth">
					<el-input v-model="serviceData.mobile" autocomplete="off" size="small">
					</el-input>
				</el-form-item>
				<el-form-item label="楼盘" prop="estate" :label-width="formLabelWidth">
					<el-input v-model="serviceData.estate" autocomplete="off" size="small">
					</el-input>
				</el-form-item>
				<el-form-item label="房号" prop="building" :label-width="formLabelWidth">
					<el-input v-model="serviceData.building" autocomplete="off" size="small">
					</el-input>
				</el-form-item>
				<el-form-item label="户型" prop="house_type" :label-width="formLabelWidth">
					<el-input v-model="serviceData.house_type" autocomplete="off" size="small" style="width: 50%;">
					</el-input>
				</el-form-item>
				<el-form-item label="面积" prop="area" :label-width="formLabelWidth">
					<el-input v-model="serviceData.area" autocomplete="off" size="small" style="width: 50%;">
					</el-input>
				</el-form-item>
				<el-form-item label="收货地址" prop="address" :label-width="formLabelWidth">
					<el-input v-model="serviceData.address" autocomplete="off" size="small" style="width: 100%;min-width: 500px;">
					</el-input>
				</el-form-item>
			</el-form>
		</el-card>
		<el-card class="box-card mt-4">
			<div slot="header" class="clearfix">
				<span class="font-weight-bolder">验收结果</span>
			</div>
			<el-tabs v-model="tabIndex" @tab-click="handleClick">
				<el-tab-pane v-for="(tab,tabI) in serviceData.detail_report" :key="tabI" :label="tab.name">
					<el-form style="width: 80%;">
						<el-form-item v-if="tab.point" label="工程监理控制要点:" :label-width="formLabelWidth">
							<div id="">
								<div id="" v-for="(pointitem,pointindex) in tab.point" :key="pointindex">
									{{pointitem}}
								</div>
							</div>
						</el-form-item>
						<el-form-item v-if="tab.record" label="工程过程巡检:" :label-width="formLabelWidth">
							<el-timeline v-if="tab.record.length > 0">
							    <el-timeline-item v-for="(recorditem,recordindex) in tab.record" :key="recordindex" :timestamp="recorditem.date" placement="top">
									<el-card>
										<div class="d-flex align-items-center">
											<div class="mr-2">
												巡检日期
											</div>
											<el-date-picker size="small" value-format="yyyy-MM-dd" v-model="recorditem.date" type="date" placeholder="选择日期"></el-date-picker>
											<el-button class="ml-auto" size="small" type="danger" @click="deleteRecord(recordindex)">删除记录</el-button>
										</div>
										<div class="">
											<div class="">
												巡检问题
											</div>
											<el-input v-model="recorditem.problem" type="textarea" placeholder="请描述问题"></el-input>
											<div class="">
												问题图片
											</div>
											<div class="d-flex">
												<div class="el-upload-list--picture-card d-flex" style="flex-wrap: wrap;" @click="getTarget(recordindex)">
													<div class="el-upload-list__item" style="position: relative;" v-for="(imgitem,imgindex) in recorditem.imgs" :key="imgindex">
														<el-image class="el-upload-list__item-thumbnail" :src="$conf.serverUrl+'storage/'+imgitem" :preview-src-list="recorditem.imgs | imgUrl"></el-image>
														<div class="d-flex align-items-center justify-content-center p-1" style="position: absolute; top:0;right: 0;"
															 @click="problemImgRemove(recordindex,imgindex)">
															 <i class="el-icon-error text-white" style="font-size: 20px;"></i>
														</div>
													</div>
													<el-upload action="/admin/service/uploadimg"
															   :http-request="problemImgUpload"
															   :headers="{token:$store.state.user.token}"
															   name="image[]"
															   :show-file-list="false"
															   multiple list-type="picture-card">
														<i class="el-icon-plus"></i>
													</el-upload>
												</div>
											</div>
										</div>
										<div class="">
											<div class="">
												监理意见
											</div>
											<div class="">
												<el-input v-model="recorditem.suggestion" type="textarea"></el-input>
											</div>
										</div>
									</el-card>
							    </el-timeline-item>
							</el-timeline>
							<div class="">
								<el-button class="w-100" type="primary" @click="addRecord(tabI)">新增记录</el-button>
							</div>
						</el-form-item>
						<el-form-item label="验收问题汇总:" :label-width="formLabelWidth" v-if="tabI == 5">
							<el-input :rows="5" type="textarea" placeholder="" v-model="tab.collect"></el-input>
						</el-form-item>
						<el-form-item label="验收问题图片:" prop="address" :label-width="formLabelWidth" v-if="tab.imgs">
							<div class="d-flex">
								<div class="el-upload-list--picture-card d-flex" style="flex-wrap: wrap;">
									<div class="el-upload-list__item" style="position: relative;" v-for="(imgitem,imgindex) in tab.imgs" :key="imgindex">
										<el-image class="el-upload-list__item-thumbnail" :src="$conf.serverUrl+'storage/'+imgitem" :preview-src-list="tab.imgs | imgUrl"></el-image>
										<div class="d-flex align-items-center justify-content-center p-1" style="position: absolute; top:0;right: 0;"
											 @click="reportImgRemove(imgindex)">
											 <i class="el-icon-error text-white" style="font-size: 20px;"></i>
										</div>
									</div>
									<el-upload action="/admin/service/uploadimg"
											   :http-request="reportImgUpload"
											   :headers="{token:$store.state.user.token}"
											   name="image[]"
											   :show-file-list="false"
											   multiple list-type="picture-card">
										<i class="el-icon-plus"></i>
									</el-upload>
								</div>
							</div>
						</el-form-item>
						<div class="" v-if="tab.statistics">
							<el-form-item v-for="(item,index) in tab.statistics" :label="item.itemname" :label-width="formLabelWidth">
								<el-input :rows="5" type="textarea" placeholder="" v-model="item.content"></el-input>
							</el-form-item>
						</div>
					</el-form>
				</el-tab-pane>
			</el-tabs>
		</el-card>
		<div style="width: 100%;height: 50px;"></div>
		<div class="bg-white rounded d-flex align-center" style="position: fixed;left: auto;bottom: 0;right: auto;z-index: 10;height: 4rem;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);"
			 :style="'width: '+operationWidth+'px;'">
			<div class="w-100 d-flex justify-content-end p-3 pr-3" style="height: 100%;">
				<el-button class="mr-1" style="width: 10%;" size="small" @click="cancelClick">取消</el-button>
				<el-button style="width: 10%;" size="small" type="primary" @click="submit">保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				serviceId: '',
				serviceData: {},
				operationWidth:0,
				tabIndex: 0,
				formLabelWidth: '150px',
				serviceRules: {
					name: [{
						required: true,
						message: '请输入客户姓名',
						trigger: 'blur'
					}, ],
					mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					estate: [{
						required: true,
						message: '请输入楼盘',
						trigger: 'blur'
					}],
					building: [{
						required: true,
						message: '请输入房号',
						trigger: 'blur'
					}],
					house_type: [{
						required: true,
						message: '请输入户型',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请输入面积',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入收货地址',
						trigger: 'blur'
					}]
				},
				deleteImgList:[],
				targetRecordIndex:0,
				problemDeleteImgList:[]
			};
		},
		filters: {
			imgUrl(imgList) {
				let list = []
				for(let v of imgList){
					list.push('http://api.fjawgc.com/storage/'+v)
				}
				return list
			}
		},
		created() {
			if (this.$route.params.serviceId) {
				window.sessionStorage.setItem('targetId', this.$route.params.serviceId)
			}
			this.serviceId = parseInt(window.sessionStorage.getItem('targetId'))
			if (!this.serviceId) {
				this.$message({
					message: 'id丢失',
					type: 'error'
				});
			}
			this.getServiceData()
		},
		beforeDestroy() {
			window.sessionStorage.setItem('targetId', 0)
		},
		mounted() {
			this.operationWidth = this.$refs.content.$el.offsetWidth
		},
		methods: {
			getServiceData() {
				this.layout.loading = true
				let url = '/admin/service/edit/' + this.serviceId
				this.axios.get(url, {
						token: true
					})
					.then((res) => {
						console.log(res)
						this.serviceData = res.data.data
						this.layout.loading = false
					})
					.catch((err) => {
						console.log(err)
					})
			},
			reportImgUpload(file) {
				let fileData = new FormData()
				fileData.append('image', file.file);
				let url = file.action
				this.axios.post(url, fileData,{
						token: true
					})
					.then((res) => {
						this.serviceData.detail_report[this.tabIndex].imgs.push(res.data.data.imgs)
					})
					.catch((err) => {
						console.log(err)
					})
			},
			reportImgRemove(imgindex) {
				let img = this.serviceData.detail_report[this.tabIndex].imgs[imgindex]
				//将图片添加进待删除列表
				this.deleteImgList.push(img)
				//将图片从汇总列表中删除
				this.serviceData.detail_report[this.tabIndex].imgs.splice(imgindex,1)
			},
			getTarget(recordindex) {
				this.targetRecordIndex = recordindex
			},
			problemImgUpload(file) {
				let fileData = new FormData()
				fileData.append('image', file.file);
				let url = file.action
				this.axios.post(url, fileData,{
						token: true
					})
					.then((res) => {
						this.serviceData.detail_report[this.tabIndex].record[this.targetRecordIndex].imgs.push(res.data.data.imgs)
					})
					.catch((err) => {
						console.log(err)
					})
			},
			problemImgRemove(recordindex,imgindex) {
				let img = this.serviceData.detail_report[this.tabIndex].record[recordindex].imgs[imgindex]
				//将图片添加进待删除列表
				this.problemDeleteImgList.push(img)
				//将图片从问题中删除
				this.serviceData.detail_report[this.tabIndex].record[recordindex].imgs.splice(imgindex,1)
			},
			uploadPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			uploadSuccess(res, file, fileList) {
				this.serviceData.detail_report[this.tabIndex].imgs.push(res.data.imgs[0])
			},
			uploadError(err, file, fileList) {
				console.log(err)
			},
			submit() {
				this.$refs['serviceRuleForm'].validate((valid) => {
					if (valid) {
						let url = '/admin/service/update/' + this.serviceId
						this.axios.post(url, this.serviceData, {
								token: true
							})
							.then((res) => {
								this.$message({
									message: '保存成功',
									type: 'success'
								})
								if(this.deleteImgList.length > 0){
									this.deleteImgs(this.deleteImgList)
								}
								if(this.problemDeleteImgList.length > 0){
									this.deleteImgs(this.problemDeleteImgList)
								}
							})
							.catch((err) => {
								this.$message.error({
									message: '保存失败'
								})
							})
					}else{
						this.$message.warning({
							message: '保存失败，缺少必填项'
						})
					}
				})
			},
			deleteImgs(imgList) {
				console.log(this.deleteImgList)
				let url = '/admin/service/deleteimg'
				this.axios.post(url, {delimgs:imgList}, {
							token: true
						})
						.then((res) => {
							console.log(res)
						})
						.catch((err) => {
							console.log(err)
						})
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			cancelClick() {
				this.$router.back()
			},
			// 新增工程巡检记录
			addRecord(index) {
				let recorditem = {
					date:'',
					problem:'',
					imgs:[],
					suggestion:''
				}
				this.serviceData.detail_report[index].record.push(recorditem)
			},
			// 删除巡检记录
			deleteRecord(recordindex) {
				let delrecordproblem = this.serviceData.detail_report[this.tabIndex].record[recordindex]
				let that = this
				this.deleteImgList = this.deleteImgList.concat(delrecordproblem.imgs)
				this.serviceData.detail_report[this.tabIndex].record.splice(recordindex,1)
			}
		}
	};
</script>

<style>
</style>
